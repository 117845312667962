function getElement(element) {
    var cs = getComputedStyle(element);

    var paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    var paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

    var borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
    var borderY = parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

    // Element width and height minus padding and border
    elementWidth = element.offsetWidth - paddingX - borderX;
    elementHeight = element.offsetHeight - paddingY - borderY;
    return {
        'width' : elementWidth,
        'height': elementHeight
    }
}
function generateColors(howMany, target) {
    let container = document.querySelector(target);
    container.innerHTML = "";
    for (let i = 0; i < howMany; i++) {
        // generating a random hex color code
        let randomHex = Math.floor(Math.random() * 0xffffff).toString(16);
        randomHex = `#${randomHex.padStart(6, "0")}`;

        // creating a new 'li' element and inserting it to the container
        const color = document.createElement("li");
        color.classList.add("color");
        color.innerHTML = `<div class="rect-box" style="background: ${randomHex}"></div>
                           <span class="hex-value">${randomHex}</span>`;
        container.appendChild(color);
    }
}
$.fn.hasAttr = function(name) {
   return this.attr(name) !== undefined;
};
function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
// function getTagsInRange(data, 'data-index', min, max) {
//     let collection = [];
//     collection = $("ul>li").filter(function(i, el) {
//         var index = $(el).data("index");
//         return index >= min && index <= max;
//     });
//     console.log(collection);
// });
function compare( a, b ) {
    if ( a.name < b.name ){
        return -1;
    }
    if ( a.name > b.name ){
        return 1;
    }
    return 0;
}
function getGridPage(id, page, rop, data) {
    let pages = Math.ceil(data.length / rop);
    let tgb = $('#'+ id).find('.grid-body');
    tgb.html('');
    let start = rop * (page - 1);
    let stop = page * rop;
    for(let i = start; i < stop; i++) {
        let el =  $('#'+ id).closest('.content').find("div.tag-default[data-index='" + i + "']").clone();
        $(el).removeClass('tag-default').removeClass('d-none').addClass('d-inline-block');
        tgb.append(el);
    }

    let tgf = $('#' + id).find('.grid-footer');
    let pagination = '';
    pagination += '<nav aria-label="pagination">\n';
    pagination += '\t<ul class="pagination pagination-grid justify-content-center">\n';

    // let pdisabled = (parseInt(page) === 0) ? 'disabled':'';
    // pagination += '\t\t<li data-page="' + parseInt(page - 1) + '" class="page-item ' + pdisabled + '">\n';
    // pagination += '\t\t\t<a class="page-link">Previous</a>\n';
    // pagination += '\t\t</li>\n';

    if(pages > 1) {
        for(let j = 0 ; j < pages; j++) {
            let current = parseInt(j + 1);
            if(( j + 1) === parseInt(page)) {
                pagination += '<li data-page="' + current + '" class="page-item active"><a class="page-link" href="#">' + current +'</a></li>';
            }
            else {
                pagination += '<li data-page="' + current + '" class="page-item"><a class="page-link" href="#">' + current +'</a></li>';
            }

        }
    }
    // let ndisabled = (parseInt(page) === pages) ? 'disabled':'';
    // pagination += '\t\t<li data-page="' + parseInt(page + 1) + '" class="page-item ' + ndisabled + '">\n';
    // pagination += '\t\t\t<a class="page-link">Next</a>\n';
    // pagination += '\t\t</li>\n';

    pagination += '\t</ul>\n';
    pagination += '</nav>\n';
    tgf.html(pagination)
}
function buildGrid(sel, rop, els) {
    let id = makeid(10);
    sel.find('.tag').each(function(index) {
        $(this).removeClass('d-inline-block').addClass('d-none');
    })
    let parent =sel.get(0);
    let container = document.createElement('div');
    container.setAttribute("id", id);
    container.className='grid-component';
    container.dataset.page = 1;
    container.dataset.rop = rop;
    let gheader = document.createElement('div');
    gheader.className='grid-header text-center w-100';
    // gheader.innerHTML ='Total : ' + els.length + ' items';
    let gbody = document.createElement('div');
    gbody.className='grid-body';
    gbody.innerHTML = "";
    let gfooter = document.createElement('div');
    gfooter.className='grid-footer';
    gfooter.innerHTML= '';
    container.appendChild(gheader);
    container.appendChild(gbody);
    container.appendChild(gfooter);
    parent.appendChild(container);
    getGridPage(id, 1, rop, els);
}
function buildTag(tag) {
    return "<tag id='" + tag.id + "' title='" + tag.value + "' contenteditable='false' spellcheck='false' class='tagify__tag'>\n" +
        "                        <x title='remove tag' class='tagify__tag__removeBtn'></x>\n" +
        "                        <div><span class='tagify__tag-text'>"+tag.value+ "</span></div>" +
        "                    </tag>";
}
function getCategoryTags(elx) {
    let result = [];
    elx.each(function(index, element) {
        let x = {
            id : element.id,
            value : element.title
        }
        result.push(x);
    })
    return result;
}
$(document).ready(function () {
    let input = document.querySelector('.customLook');
    window['tagify'] = new Tagify(input);

	window['tagsDT'] = $('#tags').dataTable( {
        responsive: true,
        pageLength : 4,
        lengthChange: false,
        bAutoWidth: false,
        aoColumns : [
            { sWidth: '20%' },
            { sWidth: '75%' },
            { sWidth: '5%' }
        ],
        columnDefs: [
            {
                className: "categories",
                "targets": [0]
            },
            {
                className: "tags",
                "targets": [1]
            },
            {
                className: "actions text-center",
                "targets": [2]
            },
            {
                    "targets": [2],
                    "orderable": false
            }
        ],
        createdRow: function( row, data, dataIndex ) {
            $( row ).addClass('row-'+ dataIndex);
            $( row ).attr("data-bs-toggle", "popover");
            $( row ).attr("data-bs-title", "Info");
            $( row ).attr("data-bs-content",data.description);
            $( row ).attr('data-id', data.id);
            $( row ).attr('data-min', data.min_tags);
            $( row ).attr('data-max', data.max_tags);
            $( row ).attr('data-color', data.color);
            $( row ).find('td:eq(0)').addClass("categories");
            $( row ).find('td:eq(1)').addClass("tags");
            $( row ).find('td:eq(2)').addClass("actions");
        },
        "ajax": {
            url: route('category.list'),
            method : "GET",
            "dataSrc": function ( json ) {
                for ( var i=0, ien=json.length ; i<ien ; i++ ) {
                    let rtags = json[i].tags.sort( compare );
                    let tags = '<button class="collapsible""><i class="fa-solid fa-plus"></i></button>\n';
                    tags += '<div class="content">\n';
                    tags += '<p>';
                    for(let j = 0; j < rtags.length; j++) {
                        tags += '<div data-id="'+rtags[j].id+'" data-index="'+j+'" title="' + rtags[j].name + '" class="tag tag-default d-inline-block shadow-sm p-1 px-2 mx-1 rounded" style="background:'+json[i].color+';"><span class="tag-name">' + rtags[j].name + '</span></div>';
                    }
                    tags +='</p></div>';
                    let actions = '<div class="btn-group" role="group" aria-label="Basic example">';
                    actions += '<button data-id="' + json[i].id + '" type="button" class="btn btn-info button-n4m edit-category"><i class="fa-solid fa-pencil"></i></button>';
                    actions += '<button data-id="' + json[i].id + '" type="button" class="btn btn-danger button-n4m delete-category" ><i class="fa-solid fa-trash"></i></button>';
                    actions += '</div>';
                    json[i][0] = json[i].name;
                    json[i][1] = tags;
                    json[i][2] = actions;
                }
                return json;
            }
        },
        "order": [[ 0, "asc" ]]
    });
    $(document).on("click", ".collapsible", function(e) {
        let tg = $(this).parent().find(".content");
        let data = $(this).parent().find(".content").find('.tag-default');
        this.classList.toggle("active");
        if(this.classList.value.indexOf('active') >= 0) {
            this.innerHTML='<i class="fa-solid fa-minus"></i>';
            if($(tg).find('.grid-component').length === 0) {
                buildGrid(tg, 50, data);
            }
        }
        else {
            this.innerHTML='<i class="fa-solid fa-plus"></i>';
        }
        let content = this.nextElementSibling;
        if (content.style.maxHeight){
            content.style.maxHeight = null;
        } else {
            //content.style.maxHeight = content.scrollHeight + "px";
            content.style.maxHeight = "100%";
        }
    });
    $(document).on("click", ".pagination-grid .page-item ", function(e) {
        let page = $(this).attr('data-page');
        let grid = $(this).closest('.grid-component');//document.getElementsByClassName('grid-component');
        let id = grid.attr('id');
        let rop = grid.attr('data-rop');
        let els = grid.closest('td').find(".content").find('.tag-default');
        getGridPage(id, page, rop, els);
    });
    $('#editCategoryModal').on('shown.bs.modal', function () {
        let howMany = 60;
        generateColors(howMany, ".color-container");
    });
    $(document).on("keyup", ".form-control", function(e) {
        // if($(e.target).hasClass("min-tags")) {
        //     if(parseInt(e.target.value) === 0) {
        //         Swal.fire(
        //             'Warning!',
        //             'Minimum value is less 1!',
        //             'warning'
        //         );
        //         e.target.value = 1;
        //         return false;
        //     }
        // }
        let old_value = e.target.value;
        if($(e.target).hasClass("min-tags")) {
            if(document.getElementById('min_tags').value.length === 0 ) {
                Swal.fire(
                    'Warning!',
                    'Please add min tags value!',
                    'warning'
                );
                e.target.value = old_value;
                return false;
            }
            if(parseInt(e.target.value) > parseInt(document.getElementById('max_tags').value)) {
                Swal.fire(
                    'Warning!',
                    'Minimum value cannot be greater than max value!',
                    'warning'
                );
                e.target.value = "";
                return false;
            }
            else {
                // if(parseInt(e.target.value) === parseInt(document.getElementById('max_tags').value)) {
                //     Swal.fire(
                //         'Warning!',
                //         'Minimum value cannot be equal with max value!',
                //         'warning'
                //     );
                //     e.target.value = "";
                //     return false;
                // }
            }
        }
        if($(e.target).hasClass("max-tags")) {
            if(document.getElementById('max_tags').value.length === 0 ) {
                Swal.fire(
                    'Warning!',
                    'Please add min tags value!',
                    'warning'
                );
                e.target.value = old_value;
                return false;
            }
            if(parseInt(e.target.value) < parseInt(document.getElementById('min_tags').value)) {
                Swal.fire(
                    'Warning!',
                    'Maximum value cannot be less than min value!',
                    'warning'
                );
                e.target.value = "";
                return false;
            }
            else {
                // if(parseInt(e.target.value) === parseInt(document.getElementById('min_tags').value)) {
                //     Swal.fire(
                //         'Warning!',
                //         'Maximum value cannot be equal with min value!',
                //         'warning'
                //     );
                //     e.target.value = "";
                //     return false;
                // }
            }
        }
        let valid = document.getElementById('update_form').checkValidity();
        if(valid)
        {
            $("#save_tags").attr("disabled", false)
        }
        else {
            $("#save_tags").attr("disabled", true)
        }
    });
    $('table#tags').on('mouseenter', 'tr', function() {
        if($(this).hasAttr("data-bs-content")) {
            $(this).popover('show');
        }
    });
    $('table#tags').on('mouseleave', 'tr', function() {
        if($(this).hasAttr("data-bs-content")) {
            $(this).popover('hide');
        }
    });

    $(document).on("click", ".add-tag-button", function (e) {
        if($("#max_tags").val() === "") {
            Swal.fire(
                'Error!',
                'Please set maximum tags number!',
                'error'
            );
            return false;
        }

        // let tagsAccepted = parseInt($("#max_tags").val());
        // let tagsNumber = $("article").find("tags").find("tag").length;
        // if(tagsNumber >= tagsAccepted) {
        //     Swal.fire(
        //         'Warning!',
        //         'You reach maximum tags number!',
        //         'warning'
        //     );
        //     return false;
        // }

        Swal.fire({
            title: "New Tag!",
            text: "Add new tag text",
            input: 'text',
            showCancelButton: true ,
            confirmButtonColor: 'green'
            }).then((result) => {
            if (result.value) {
                window['tagify'].addTags([result.value]);
            }
        });
        return false;
    });

    $(document).on("click", "#add_category", function (e) {
        e.preventDefault();
        window['tagify'].removeAllTags();

        $("#editCategoryModal").find(".modal-body").find(".category-name").val("");
        $("#editCategoryModal").find(".modal-body").find(".category-description").val("");
        $("#min_tags").val("");
        $("#max_tags").val("");
        $("#editCategoryModal").find(".modal-body").find("article").find("tags").find("tag").remove();
        $("#editCategoryModal").attr('data-id', null);
        $("#editCategoryModal").find(".modal-body").find(".min-tags").val("");
        $("#editCategoryModal").find(".modal-body").find(".max-tags").val("");
        $("#editCategoryModal").find(".modal-body").find(".user-select-color").text("(UNSELECTED)");
        $("#editCategoryModal").find("div.modal-header > h4").text("Add Category");
        $("#editCategoryModal"). modal('show');
    });

    $(document).on("click",".edit-category", function(e) {
        e.preventDefault();
        // window['tagify'].removeAllTags();
        let cid = $(this).attr("data-id");
        let self = $(this);
        $.ajax({
            url : route('category.edit', cid),
            type:"GET",
            success:function(result){
                $("#editCategoryModal").find("div.modal-header > h4").text("Edit Category");
                let tr = self.closest("tr");
                $("#save_tags").attr("disabled", false);
                $("#editCategoryModal").attr('data-id', result.id);
                $("#editCategoryModal").find(".modal-body").find(".category-name").val(result.name);
                $("#editCategoryModal").find(".modal-body").find(".category-description").val(result.description);
                $("#editCategoryModal").find(".modal-body").find(".min-tags").val(result.min_tags);
                $("#editCategoryModal").find(".modal-body").find(".max-tags").val(result.max_tags);
                $("#editCategoryModal").find(".modal-body").find(".user-select-color").text("("+ result.color + ")");
                $("#editCategoryModal").find(".modal-body").find("article").find("tags").find("tag").remove();

                let tmp = [];
                let ctags = tr.find("td.tags").find(".tag");
                for(let i = 0; i < ctags.length; i++) {
                    let y = $(ctags[i]).find("span.tag-name").text();
                    let z = $(ctags[i]).attr("data-id");
                    tmp[i] = {
                        'value' : y,
                        'id' : z
                    };
                }
                // let tags = tmp.join();
                // tagify.removeAllTags();
                // tagify.addTags(tmp);
                window['tagify'].removeAllTags();
                window['tagify'].addTags(tmp);
                // let target = $("#editCategoryModal").find("article").find("tags");
                // target.html("");
                // for(let i = 0; i < tmp.length; i++) {
                //     let tg = buildTag(tmp[i]);
                //     target.append(tg);
                // }
                $("#editCategoryModal"). modal('show');
            },
            error:function(data){
                console.log('error');
            }
        });
        return false;
    });
    $(document).on("click",".tagify__tag__removeBtn", function(e) {
        let self = this;
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this tag?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete!'
        }).then((result) => {
            if (result.isConfirmed) {
                $(self).closest('tag').remove();
            }
        })
    });

    $(document).on("click","#save_tags", function(e) {
        let form  = document.getElementById('update_form');
        let valid = form.checkValidity();
        if(!valid)
        {
            Swal.fire(
                'Warning!',
                'Complete all fields in form!',
                'warning'
            );
            return false;
        }
        form.classList.add('was-validated');

        let catid = $("#editCategoryModal").attr('data-id');
        let tr = $("tr." + $(this).closest(".modal").attr("data-row"));
        tr.find("td.tags").html("");

        let color = $("#category_color").text().slice(1,-1);
        if(!CSS.supports('color', color)) {
            Swal.fire(
                'Error!',
                'Please select the color for category!',
                'error'
            );
            return false;
        }

        let minValue = document.getElementById('min_tags').value;
        let maxValue = document.getElementById('max_tags').value;
        // let tagsNumber = null;
        // if(document.getElementById('inputTags').value.length === 0) {
        //     tagsNumber = 0;
        // }
        // else {
        //     tagsNumber = JSON.parse(document.getElementById('inputTags').value).length;
        // }


        // if((tagsNumber >= minValue) && (tagsNumber <= maxValue)) {
        //
        // }
        // else {
        //     Swal.fire(
        //         'Error!',
        //         'Invalid tags number! <br>You must have Minimum ' + minValue + ' and Maximum ' + maxValue + ' tags!',
        //         'error'
        //     );
        //     return false;
        // }
        let tags = getCategoryTags($("#editCategoryModal").find("article").find("tags").find("tag"));
        let selections = {
            'catid'         : catid,
            'color'         : color,
            'name'          : $("#category_name").val(),
            'description'   : $("#category_description").val(),
            'min_tags'      : $("#min_tags").val(),
            'max_tags'      : $("#max_tags").val(),
            'tags'          : JSON.stringify(tags)
        };
        $.ajax({
            url : route('category.update'),
            data  : selections,
            type:"POST",
            success:function(result){
                $('#tags').DataTable().ajax.reload(null, false).draw();
                $("#editCategoryModal"). modal('hide');
            },
            error:function(data){
                console.log('error');
            }
        });
        return false;
    });

    $(document).on("click",".delete-category", function(e) {
        let tr = $(this).closest("tr");
        let cname = tr.find("td.categories").text();
        let catid = tr.attr("data-id");
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete category named "' + cname + '"?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete!'
            }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    url: route('category.delete', catid),
                    type:"delete",
                    success:function(result){
                        $('#tags').DataTable().ajax.reload(null, false).draw();
                        Swal.fire(
                            'Deleted!',
                            'Category has been deleted.',
                            'success'
                        );
                    },
                    error:function(data){
                        console.log('error');
                    }
                });
                return false;
            }
        })
    });

    $(document).on("click","li.color", function(e) {
        let el = $(this);
        $("#editCategoryModal").find(".modal-body").find(".color-container").find("li.color-selected").removeClass("color-selected");
        if(!el.hasClass("color-selected")) {
            el.addClass("color-selected");
            let color = el.find(".hex-value").text();
            el.closest(".mb-3").find(".user-select-color").html('(' + color + ')');
            $("#editCategoryModal").find(".modal-body").find("article").find("tags").find("tag").each(function () {
                $(this).css("--tag-bg", color);
            })
        }
        else {
            el.removeClass("color-selected");
        }
    });
});
